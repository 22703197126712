import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconId48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <line x1="14" y1="7" x2="21" y2="7"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"/>
            <line x1="14" y1="10" x2="21" y2="10"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"/>
            <rect x="1" y="4" width="22" height="16"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"
                  strokeLinejoin="round"/>
            <mask id="path-4-inside-1_10469_208309" fill="white">
                <path fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 18C3 15.7909 4.79086 14 7 14H10C12.2091 14 14 15.7909 14 18V21H3V18Z"
                      fill={props.fill || 'currentColor'}/>
            </mask>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14 21V23H16V21H14ZM3 21H1V23H3V21ZM7 16H10V12H7V16ZM12 18V21H16V18H12ZM14 19H3V23H14V19ZM5 21V18H1V21H5ZM10 16C11.1046 16 12 16.8954 12 18H16C16 14.6863 13.3137 12 10 12V16ZM7 12C3.68629 12 1 14.6863 1 18H5C5 16.8954 5.89543 16 7 16V12Z"
                  fill={props.fill || 'currentColor'}
                  mask="url(#path-4-inside-1_10469_208309)"/>
            <circle cx="8.5" cy="9.5" r="2.5" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
        </svg>
    );
}

export function IconId24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <line x1="14" y1="7" x2="21" y2="7"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"/>
            <line x1="14" y1="10" x2="21" y2="10"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"/>
            <rect x="1" y="4" width="22" height="16"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"
                  strokeLinejoin="round"/>
            <mask id="path-4-inside-1_10469_208309" fill="white">
                <path fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 18C3 15.7909 4.79086 14 7 14H10C12.2091 14 14 15.7909 14 18V21H3V18Z"
                      fill={props.fill || 'currentColor'}/>
            </mask>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14 21V23H16V21H14ZM3 21H1V23H3V21ZM7 16H10V12H7V16ZM12 18V21H16V18H12ZM14 19H3V23H14V19ZM5 21V18H1V21H5ZM10 16C11.1046 16 12 16.8954 12 18H16C16 14.6863 13.3137 12 10 12V16ZM7 12C3.68629 12 1 14.6863 1 18H5C5 16.8954 5.89543 16 7 16V12Z"
                  fill={props.fill || 'currentColor'}
                  mask="url(#path-4-inside-1_10469_208309)"/>
            <circle cx="8.5" cy="9.5" r="2.5" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
        </svg>
    );
}