import { Locale } from '../constants/locale-constants';
import { TMDB_IMAGE_RESOLUTION, VideoSites } from '../constants/tmdb-constants';
import { VIMEO_THUMBNAIL_IMAGE_DEFAULT, VIMEO_VIDEO_URL } from '../constants/vimeo-constants';
import { YOUTUBE_THUMBNAIL_IMAGE_MAX_RES, YOUTUBE_VIDEO_URL } from '../constants/youtube-constants';
import { CrewDto } from '../models/movie/CrewDto.types';
import { ImageDto } from '../models/movie/ImagesDto.types';
import { MovieDto } from '../models/movie/MovieDto.types';
import { ReleaseDateType } from '../models/movie/ReleaseDateType.types';
import { Status } from '../models/movie/Status.enum';
import { TranslationDataDto } from '../models/movie/TranslationDataDto.types';
import { TranslationDto } from '../models/movie/TranslationDto.types';
import { VideoDto } from '../models/movie/VideoDto.types';
import { VideoType } from '../models/movie/VideoType.types';

import { loadAsset } from './asset-helper';
import { getCountryFromCode } from './international-helper';
import { getCountryCodeFromLocale, getLocaleFromLanguage } from './locale-helper';

const MoviePosterPlaceholder = loadAsset('/images/placeholders/portrait/im-posterFilmPortrait.jpg');
const MovieBackdropPlaceholder = loadAsset('/images/placeholders/landscape/im-posterFilmLandscape.jpg');

export type VideoDtoEnhanced = VideoDto & {
    fullUrl: string;
    thumbnailUrl: string;
};

export type MovieImageOptions = {
    useTmdbUrlPrefix?: boolean;
    resolution?: TMDB_IMAGE_RESOLUTION;
    includeImagesWithoutLocale?: boolean;
    placeholder?: string;
};

export function getMovieEnhancedVideos(
    movie?: MovieDto,
    options?: { seekToSeconds?: number; locale?: string }
): VideoDtoEnhanced[] {
    if (!movie?.videos?.results) return [];

    // Map videos to include full URL and thumbnail URL
    let videos: VideoDtoEnhanced[] = movie.videos.results.flatMap((video) => {
        return {
            ...video,
            fullUrl:
                video.site === VideoSites.Vimeo
                    ? VIMEO_VIDEO_URL(video.key!, options?.seekToSeconds ?? 0)
                    : YOUTUBE_VIDEO_URL(video.key!, options?.seekToSeconds ?? 0),
            thumbnailUrl:
                video.site === VideoSites.Vimeo
                    ? VIMEO_THUMBNAIL_IMAGE_DEFAULT(video.key!)
                    : YOUTUBE_THUMBNAIL_IMAGE_MAX_RES(video.key!)
        };
    });

    // Filter videos based on locale
    if (options?.locale) {
        videos = videos.filter((video) => !video.iso6391 || video.iso6391 === options.locale);
    }

    // Sort videos, ensuring trailers come first
    return videos.sort((a, b) => {
        if (a.type === VideoType.Trailer && b.type !== VideoType.Trailer) return -1;
        if (a.type !== VideoType.Trailer && b.type === VideoType.Trailer) return 1;
        return 0;
    });
}

export function getMovieTrailer(
    movie?: MovieDto,
    options?: { seekToSeconds?: number; locale?: string }
): VideoDtoEnhanced | undefined {
    const trailerVideos: VideoDtoEnhanced[] = getMovieEnhancedVideos(movie, options);
    return trailerVideos.find((video) => video.type === VideoType.Trailer) || trailerVideos[0];
}

export function movieSlugOrCineamoId(movie?: MovieDto): number | string | undefined {
    return movie?.slug || movie?.cineamoId;
}

export function getCrewMembersByJob(movie: MovieDto, ...job: string[]): CrewDto[] {
    if (movie?.credits?.crew) {
        const directorJobNames: string[] = job;
        return (
            movie.credits.crew.filter((member) =>
                directorJobNames.flatMap((jobName) => jobName.toLowerCase()).includes(member.job!.toLowerCase())
            ) || null
        );
    }
    return [];
}

export function getRandomMovieSearchQuery(): string {
    const currentMonth = new Date().getMonth() + 1; // +1 because otherwise it's the index starting by 0=January
    const monthQueries: Record<string, string[]> = {
        '2': ['valentinstag große gatsby crazy stupid love', 'footloose burlesque crazy rich to all the boys'],
        '3,4,5': [
            'ferris macht blau 10 dinge die ich an dir hasse drachenzähmen leicht  gemacht',
            'footloose burlesque spider-man a new universe to all the boys',
            'frühling fack ju göthe das wandelnde schloss die wilden hühner',
            'toy story',
            'stolz und vorurteil notting hill high ferris macht blau'
        ],
        '4': [
            'Ostern Ben Hur',
            'Ostern Peter Hase',
            'Ostern Zoomania Peter Hase Die Hüter des Lichts Das Leben des Brian',
            'Ostern Die Häschenschule Die Zehn Gebote'
        ],
        '6,7,8': [
            'ich weiß was du letzten sommer getan hast midsommar booksmart',
            '10 dinge die ich an dir hasse midsommar booksmart',
            'booksmart top gun guardians of the galaxy'
        ],
        '9,10': [
            'club der toten dichter harry und sally the batman',
            'harry potter und der',
            'narnia',
            'good will hunting sleepy hollow'
        ],
        '10': [
            'Halloween Nightmare Before Christmas Trick ’r Treat',
            'Halloween Nightmare Before Christmas Poltergeist Kills',
            'Halloween Scary stories to tell in the Dark The Witch Wir',
            'Halloween It Es Mother The Witch Jigsaw The Babysitter Don t Breathe'
        ],
        '10,11': [
            'Halloween hereditary elm',
            'halloween midsommar insidious',
            'knives out shutter island American psycho'
        ],
        '1,11,12': [
            'Grinch',
            'Kevin allein zu Haus',
            'harry potter und der',
            'Christmas Weihnachten',
            'Krampus',
            'Weihnachten Christmas Noelle Der Polarexpress Nussknacker Klaus',
            'sissi nussknacker polarexpress'
        ],
        default: [
            'The Batman',
            'Der Herr der Ringe',
            'Peter Pan',
            'Marvel Avengers Thor Iron Man',
            'Marvel Avengers Guardians of the Galaxy',
            'Deadpool wolverine spider-man',
            'The Batman Departed Unter Feinden Herr der Ringe Fluch der Karibik'
        ]
    };

    const randomMonthQueries = Object.keys(monthQueries)
        .flatMap((key) =>
            key.split(',').some((month) => month === currentMonth.toString()) ? monthQueries[key] : undefined
        )
        .filter((it) => it !== undefined);

    const query = randomMonthQueries.length > 0 ? randomMonthQueries : monthQueries['default'];
    const index = Math.round(Math.random() * (query.length - 1));

    return query[index] || '';
}

export function getMoviePosterImage(
    movie: MovieDto,
    locale: string | false,
    useBackdropAsFallback?: boolean,
    usePlaceholder = true,
    options?: MovieImageOptions
): string | undefined {
    const moviePosterImages = getMoviePosterImages(movie, locale, useBackdropAsFallback, options);
    if (moviePosterImages.length <= 0 && usePlaceholder) {
        return options?.placeholder || MoviePosterPlaceholder;
    }
    return moviePosterImages?.[0];
}

export function getMoviePosterImages(
    movie: MovieDto,
    locale: string | false,
    useBackdropsAsFallback = false,
    options?: MovieImageOptions
): string[] {
    let translatedImages: ImageDto[] = movie?.images?.posters ?? [];

    translatedImages.sort((imageA, imageB) => (imageB.voteAverage ?? 0.0) - (imageA.voteAverage ?? 0.0));

    if (locale) {
        const translatedImagesForLocale = translatedImages.filter(
            (image) => image.iso6391 === locale || (options?.includeImagesWithoutLocale && !image.iso6391)
        );
        if (translatedImagesForLocale.length > 0) {
            translatedImages = translatedImagesForLocale;
        }
    } else if (locale === false) {
        const nonLocaleImages = translatedImages.filter((image) => !image.iso6391);
        if (nonLocaleImages.length > 0) {
            translatedImages = nonLocaleImages;
        }
    }

    let translatedImageUrls: string[] = translatedImages
        .map((image) => image.filePath ?? '')
        .filter((url) => url.length > 0);

    if (movie && translatedImageUrls.length <= 0 && movie.posterPath && movie.posterPath.length > 0) {
        translatedImageUrls = [movie.posterPath];
    }

    if (translatedImageUrls.length <= 0 && useBackdropsAsFallback) {
        translatedImageUrls = getMovieBackdropImages(movie, locale, false, options);
    }

    if (options?.useTmdbUrlPrefix !== false) {
        return translatedImageUrls.map((imageUrl) => (options?.resolution || TMDB_IMAGE_RESOLUTION.W780) + imageUrl);
    }

    return translatedImageUrls;
}

export function getMoviePosterBlurDataImage(
    movie?: MovieDto,
    locale?: string,
    useBackdropAsFallback?: boolean,
    usePlaceholder = true
): string | undefined {
    const blurDataUrl = getMoviePosterImage(movie, locale, useBackdropAsFallback, usePlaceholder, {
        resolution: TMDB_IMAGE_RESOLUTION.W92
    });
    if (typeof blurDataUrl === 'string') {
        return blurDataUrl;
    }
    return blurDataUrl;
}

export function getMovieBackdropImage(
    movie?: MovieDto,
    locale?: string | false,
    usePosterAsFallback?: boolean,
    usePlaceholder = true,
    options?: MovieImageOptions
): string | undefined {
    const movieBackdropImages = getMovieBackdropImages(movie, locale, usePosterAsFallback, options);
    if (movieBackdropImages.length <= 0 && usePlaceholder) {
        return MovieBackdropPlaceholder;
    }
    return movieBackdropImages?.[0];
}

export function getMovieBackdropImages(
    movie: MovieDto,
    locale: string | false,
    usePostersAsFallback = false,
    options?: MovieImageOptions
): string[] {
    let translatedImages: ImageDto[] = movie?.images?.backdrops ?? [];

    translatedImages.sort((imageA, imageB) => (imageB.voteAverage ?? 0.0) - (imageA.voteAverage ?? 0.0));

    if (locale) {
        const translatedImagesForLocale = translatedImages.filter(
            (image) => image.iso6391 === locale || (options?.includeImagesWithoutLocale && !image.iso6391)
        );
        if (translatedImagesForLocale.length > 0) {
            translatedImages = translatedImagesForLocale;
        }
    } else if (locale === false) {
        const nonLocaleImages = translatedImages.filter((image) => !image.iso6391);
        if (nonLocaleImages.length > 0) {
            translatedImages = nonLocaleImages;
        }
    }

    let translatedImageUrls: string[] = translatedImages
        .map((image) => image.filePath ?? '')
        .filter((url) => url.length > 0);

    if (movie && translatedImageUrls.length <= 0 && movie.backdropPath && movie.backdropPath.length > 0) {
        translatedImageUrls = [movie.backdropPath];
    }

    if (translatedImageUrls.length <= 0 && usePostersAsFallback) {
        translatedImageUrls = getMoviePosterImages(movie, locale, false, options);
    }

    if (options?.useTmdbUrlPrefix !== false) {
        return translatedImageUrls.map((imageUrl) => (options?.resolution || TMDB_IMAGE_RESOLUTION.W1280) + imageUrl);
    }

    return translatedImageUrls;
}

export function getMovieBackdropBlurDataImage(
    movie?: MovieDto,
    locale?: string | false,
    usePosterAsFallback?: boolean,
    usePlaceholder = true
): string | undefined {
    const blurDataUrl = getMovieBackdropImage(movie, locale, usePosterAsFallback, usePlaceholder, {
        resolution: TMDB_IMAGE_RESOLUTION.W92
    });
    if (typeof blurDataUrl === 'string') {
        return blurDataUrl;
    }
    return blurDataUrl;
}

export function getMovieTitle(movie?: MovieDto, locale?: string): string | undefined {
    if (!movie) return undefined;

    const translation = getTranslationData(movie, locale, 'title');

    // If a translation is found but the title is empty and the originalLanguage matches, return the originalTitle.
    if (translation && !translation.data?.title && movie.originalLanguage === translation.iso6391) {
        return movie.originalTitle;
    }

    // Return the found title or fallback to the original titles.
    return translation?.data?.title || movie.originalTitle || movie.title;
}

export function getMovieOverview(movie?: MovieDto, locale?: string): string | undefined {
    return getTranslationData(movie, locale, 'overview')?.data?.overview || movie?.overview;
}

export function getMovieRuntime(movie?: MovieDto, locale?: string): number | undefined {
    return getTranslationData(movie, locale, 'runtime')?.data?.runtime || movie?.runtime;
}

export function getMovieAgeRating(
    movie: MovieDto,
    locale?: string,
    releaseDateType: ReleaseDateType = ReleaseDateType.THEATRICAL
): string | undefined {
    const locale_BCP47 =
        locale && locale.length > 2 ? locale : typeof navigator !== 'undefined' ? navigator.language : undefined;

    const locale_ISO31661 = locale_BCP47?.substring(3, 5);

    if (!locale_ISO31661) {
        return undefined;
    }

    const releaseDatesForLocale = movie?.releaseDates?.results?.find(
        (result) => result.iso31661?.toLowerCase() === locale_ISO31661.toLowerCase()
    )?.releaseDates;

    const releaseDateForType = releaseDatesForLocale?.find(
        (releaseDate) => releaseDate.type === releaseDateType
    )?.certification;

    const ageRatingForType =
        releaseDateForType ??
        releaseDatesForLocale?.find((releaseDate) => releaseDate.type === ReleaseDateType.THEATRICAL_LIMITED)
            ?.certification ??
        releaseDatesForLocale?.find((releaseDate) => releaseDate.type === ReleaseDateType.PREMIERE)?.certification ??
        releaseDatesForLocale?.find((releaseDate) => releaseDate.type === ReleaseDateType.PHYSICAL)?.certification ??
        releaseDatesForLocale?.find(
            (releaseDate) => releaseDate.certification != null && releaseDate.certification.length > 0
        )?.certification;

    if (!ageRatingForType) {
        return undefined;
    }

    const formattedLocale = locale_BCP47?.replace('_', '-');
    switch (formattedLocale) {
        case 'de-DE':
            return `FSK ${ageRatingForType}`;
        case 'nl-NL':
            return `Kijkwijzer ${ageRatingForType}`;
        case 'en-GB':
            return `BBFC ${ageRatingForType}`;
        case 'en-US':
            return `Rated ${ageRatingForType}`;
        default:
            return ageRatingForType;
    }
}

export function getMovieReleaseDate(movie?: MovieDto, locale?: Locale): string | undefined {
    const countryCodeFromLocale = locale?.split('_')?.[1];
    return (
        (countryCodeFromLocale &&
            movie?.releaseDates?.results
                ?.find((releaseDate) => releaseDate.iso31661?.toLowerCase() === countryCodeFromLocale.toLowerCase())
                ?.releaseDates?.find((releaseDate) => releaseDate.type === ReleaseDateType.THEATRICAL)?.releaseDate) ||
        movie?.releaseDate
    );
}

export function getMovieReleaseYear(movie?: MovieDto, locale?: Locale): string | undefined {
    const releaseDate: string | undefined = getMovieReleaseDate(movie, locale);
    return releaseDate && releaseDate.length >= 4 ? releaseDate?.substring(0, 4) : undefined;
}

export function getMovieHomepage(movie?: MovieDto, locale?: string): string | undefined {
    return getTranslationData(movie, locale, 'homepage')?.data?.homepage || movie?.homepage;
}

export function getMovieStatus(movie?: MovieDto): Status | undefined {
    return movie?.status;
}

export function getMovieProductionCountries(movie: MovieDto, locale?: string): string | undefined {
    const countryCodeFromLocale = locale ? getCountryCodeFromLocale(getLocaleFromLanguage(locale)) : undefined;
    if (!countryCodeFromLocale) {
        return undefined;
    }
    return movie.productionCountries
        ?.flatMap((value) => value.iso31661 && getCountryFromCode(value.iso31661, countryCodeFromLocale))
        .join(', ');
}

/**
 * Helper function to get a list of possible translations.
 * Let's say a movie has multiple iso31661 === 'en' translations.
 * But maybe a few of them do not have a title and others have no overview, runtime etc.
 * The we can just search for an alternative in most probably the same language 'en'.
 * For example 'en-US' has a title but 'en-GB' hasn't. Then let's use the US title instead.
 *
 * @param movie
 * @param locale
 */
function getTranslations(movie?: MovieDto, locale?: string): TranslationDto[] | undefined {
    const languageOrLocale = locale ? locale : typeof navigator !== 'undefined' ? navigator.language : undefined;
    if (!languageOrLocale) {
        return [];
    }
    const country =
        languageOrLocale.length === 5
            ? languageOrLocale.split('-')?.[1]
            : languageOrLocale.length === 2 && languageOrLocale.toUpperCase() === languageOrLocale
              ? languageOrLocale
              : undefined;
    const language = languageOrLocale.slice(0, 2);

    let translationsForCountryAndLanguage = [];
    if (country && language) {
        translationsForCountryAndLanguage =
            movie?.translations?.translations?.filter(
                (translation) => translation.iso31661 === country && translation.iso6391 === language
            ) ?? [];
    }

    let translationsForCountry = [];
    if (country) {
        translationsForCountry =
            movie?.translations?.translations?.filter((translation) => translation.iso31661 === country) ?? [];
    }

    let translationsForLanguage: TranslationDto[] = [];
    if (language) {
        translationsForLanguage =
            movie?.translations?.translations?.filter(
                (translation) => translation.iso6391.toLowerCase() === language.toLowerCase()
            ) ?? [];
    }

    // Prio order: country and language, languag only, country only
    return [...translationsForCountryAndLanguage, ...translationsForLanguage, ...translationsForCountry];
}

function getTranslationData(
    movie?: MovieDto,
    locale?: string,
    requiredProperty?: keyof TranslationDataDto
): TranslationDto | undefined {
    return getTranslations(movie, locale)?.find(
        (translation) =>
            (translation.iso6391?.length > 0 &&
                movie.originalLanguage?.length > 0 &&
                translation.iso6391 === movie.originalLanguage) ||
            translation?.data?.[requiredProperty]?.toString()?.length > 0
    );
}
