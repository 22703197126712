import { SessionProvider, signIn, useSession } from 'next-auth/react';
import { ReactNode, useEffect } from 'react';

import useRouterQueryKeyValue from '@cineamo/legacy-frontend-lib/src/hooks/useRouterQueryKeyValue';

export type SessionProviderWrapperProps = {
    session;
    children?: ReactNode;
};

function SessionProviderWrapper(props: SessionProviderWrapperProps): JSX.Element {
    return (
        <SessionProvider session={props.session}>
            <CheckSignIn>{props.children}</CheckSignIn>
        </SessionProvider>
    );
}

function CheckSignIn(props: { children: ReactNode }): JSX.Element {
    const { queryValue: authenticationSuccessful } = useRouterQueryKeyValue('authentication');
    //
    const { data: session, status } = useSession();
    useEffect(() => {
        if (!session && authenticationSuccessful == 'successful' && status === 'unauthenticated') {
            signIn('cineamo').then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return <>{props.children}</>;
}

export default SessionProviderWrapper;
