import classnames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

import LinkWithStyle from '@/components/link/LinkWithStyle';

import PermissionsGate, { PermissionGateMode } from '@cineamo/legacy-frontend-lib/src/helper/PermissonsGate';

export interface SidebarNavigationItemProps {
    className?: string;
    link?: string;
    onClick?: () => void;
    icon?: JSX.Element;
    text: string;
    neededPermission?: string;
}

function SidebarNavigationItem(props: SidebarNavigationItemProps) {
    const { className, link, onClick, icon, text, neededPermission } = props;

    const router = useRouter();

    const isIncluded =
        (router.asPath.includes(link) && link !== '/') || (router.asPath === link && router.asPath === '/');

    return (
        <div className={classnames(className)}>
            <PermissionsGate
                necessaryPermission={neededPermission}
                mode={PermissionGateMode.hide}>
                <div id={text}>
                    <LinkWithStyle
                        className={classnames(
                            'block text-body-bold-14 tracking-regular py-14 cursor-pointer',
                            'transition-all duration-100',
                            'hover:bg-darkBlue hover:text-cyan',
                            isIncluded ? 'text-cyan border-l-2' : null
                        )}
                        href={link}
                        onClick={() => {
                            if (onClick) onClick();
                        }}>
                        <div className="flex pl-20 pr-16 space-x-16">
                            {icon}
                            <div className="my-auto">{text}</div>
                        </div>
                    </LinkWithStyle>
                </div>
            </PermissionsGate>
        </div>
    );
}

export default SidebarNavigationItem;
