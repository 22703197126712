import Image from 'next/image';
import React, { CSSProperties } from 'react';
import { ToastContent, ToastOptions } from 'react-toastify';

import { ApiErrorResponse } from '../api/ApiErrorResponse.types';
import Button from '../components/ui/buttons/Button';
import { IconClose16 } from '../icons/menu/ic-close';

import { copyToClipboard } from './clipboard-helper';
import { isMobile } from './device/screen-type';

/**
 * @deprecated Please use showToastNew
 * @example
 * showToast(toast.success, null, 'Success!', 'Sub Info', '#fff', '#000023', () => { onClick }, () => { onClose })
 * @param toastType
 * @param messageIcon
 * @param messageTitle
 * @param messageBody
 * @param closeButton
 * @param backgroundColor
 * @param textColor
 * @param onClose
 * @param onClick
 * @param autoClose
 */
export default function showToast(
    toastType: (content: ToastContent, options?: ToastOptions | undefined) => React.ReactText,
    messageIcon?: string | JSX.Element,
    messageTitle?: string | JSX.Element,
    messageBody?: string | JSX.Element,
    backgroundColor?: string,
    textColor?: string,
    closeButton?: boolean | JSX.Element,
    onClose?: () => void,
    onClick?: () => void,
    autoClose?: number | false
): void {
    const style: CSSProperties = {};

    if (backgroundColor) {
        style.backgroundColor = backgroundColor;
    }
    if (textColor) {
        style.color = textColor;
    }

    function onCloseInternal() {
        if (onClose) {
            onClose();
        }
    }

    function onClickInternal() {
        if (onClick) {
            onClick();
        }
    }

    const messageElement: JSX.Element = (
        <div className="flex flex-row space-x-8 items-center">
            {messageIcon && React.isValidElement(messageIcon) ? (
                messageIcon
            ) : messageIcon && typeof messageIcon === 'string' ? (
                <div className="w-32 h-32 relative my-auto mr-10 justify-center cursor-text">
                    <Image
                        layout="fill"
                        src={messageIcon}
                        alt=""
                        sizes="100%"
                        objectFit="contain"
                    />
                </div>
            ) : null}
            <div className="flex flex-col space-y-8">
                {messageTitle && <span className="font-montserrat-semibold">{messageTitle}</span>}
                {messageBody && <span>{messageBody}</span>}
            </div>
        </div>
    );

    toastType(messageElement, {
        className: '',
        bodyClassName: 'font-montserrat-regular',
        style: { ...style },
        position: isMobile ? 'top-center' : 'top-right',
        hideProgressBar: true,
        closeOnClick: true,
        onClick: onClickInternal,
        draggable: false,
        progress: undefined,
        onClose: onCloseInternal,
        autoClose: autoClose === false ? false : 5000,
        closeButton: closeButton,
        icon: false
    });
}

export function showToastNew(options: {
    toastType: (content: ToastContent, options?: ToastOptions | undefined) => React.ReactText;
    messageIcon?: string | JSX.Element;
    messageTitle?: string | JSX.Element;
    messageBody?: string | JSX.Element;
    messageButton?: JSX.Element;
    backgroundColor?: string;
    textColor?: string;
    buttonColor?: JSX.Element;
    closeButton?: boolean | JSX.Element;
    onClose?: () => void;
    onClick?: () => void;
    autoClose?: number | false;
}) {
    const style: CSSProperties = {};

    const {
        toastType,
        messageIcon,
        messageTitle,
        messageBody,
        backgroundColor,
        textColor,
        closeButton,
        messageButton,
        onClose,
        onClick,
        autoClose
    } = options;

    if (backgroundColor) {
        style.backgroundColor = backgroundColor;
    } else {
        style.backgroundColor = '#fff';
    }
    if (textColor) {
        style.color = textColor;
    } else {
        style.color = '#000023';
    }

    function onCloseInternal() {
        if (onClose) {
            onClose();
        }
    }

    function onClickInternal() {
        if (onClick) {
            onClick();
        }
    }

    const messageElement: JSX.Element = (
        <div className="flex flex-col space-y-16 px-2">
            <div className="flex flex-row space-x-16 items-center">
                {messageIcon && React.isValidElement(messageIcon) ? (
                    messageIcon
                ) : messageIcon && typeof messageIcon === 'string' ? (
                    <div className="w-32 h-32 relative my-auto justify-center cursor-text">
                        <Image
                            layout="fill"
                            src={messageIcon}
                            alt=""
                            sizes="100%"
                            objectFit="contain"
                        />
                    </div>
                ) : null}
                <div className="w-0 flex-auto flex flex-col space-y-4">
                    {messageTitle && <span className="font-montserrat-semibold">{messageTitle}</span>}
                    {messageBody && (
                        <span className="flex flex-col whitespace-pre-wrap overflow-auto w-full max-h-[50vh]">
                            {messageBody}
                        </span>
                    )}
                </div>
            </div>
            {messageButton && (
                <div
                    className="w-full flex flex-col"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}>
                    {messageButton}
                </div>
            )}
        </div>
    );

    toastType(messageElement, {
        className: '',
        bodyClassName: 'font-montserrat-regular',
        style: { ...style },
        position: isMobile ? 'top-center' : 'top-right',
        hideProgressBar: true,
        closeOnClick: true,
        onClick: onClickInternal,
        draggable: false,
        progress: undefined,
        onClose: onCloseInternal,
        autoClose:
            autoClose === false
                ? false
                : calculateReadingTime([messageTitle, messageBody].filter((it) => !!it).join(' ')),
        closeButton: closeButton,
        icon: false
    });
}

export function showErrorToast<TErrorResponse>({
    toastType,
    messageTitle,
    error,
    buttonLabel
}: {
    toastType: (content: ToastContent, options?: ToastOptions | undefined) => React.ReactText;
    messageTitle?: string | JSX.Element;
    error?: ApiErrorResponse<TErrorResponse>;
    buttonLabel: string;
}) {
    showToastNew({
        toastType: toastType,
        messageIcon: <IconClose16 />,
        messageTitle: messageTitle,
        messageBody: error?.response?.data ? (
            <span className="italic">{JSON.stringify(error?.response?.data)}</span>
        ) : null,
        messageButton: <Button onClick={() => copyToClipboard(JSON.stringify(error))}>{buttonLabel}</Button>
    });
}

function calculateReadingTime(text) {
    const wordsPerMinute = 150; // Average reading speed
    const words = text.trim().split(/\s+/).length; // Split text by spaces to count words
    const minutes = words / wordsPerMinute;
    const milliseconds = minutes * 60 * 1000; // Convert minutes to milliseconds

    return Math.round(milliseconds); // Round to the nearest millisecond
}
