import { NavigationDataGroup } from '@/components/navigation/data/NavigationDataItem.type';

import { IconBill24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-bill';
import { IconCinemaChain24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-cinemaChain';
import { IconDistributor24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-distributor';
import { IconPieChart24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-pieChart';
import { IconProfile24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-profile';
import { IconProgram24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-program';
import { IconRoleAdministration24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-roleAdministration';
import { IconSeat24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-seat';
import { IconSend24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-send';
import { IconSlate24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-slate';
import { IconStar24 } from '@cineamo/legacy-frontend-lib/src/icons/symbols/ic-star';

export const adminNavigation = (): NavigationDataGroup[] => [
    {
        label: 'navigation.cinemas',
        children: [
            {
                title: 'navigation.cinemas',
                href: '/cinemas',
                icon: <IconSeat24 />,
                necessaryPermission: 'admin_administration'
            },
            {
                title: 'navigation.cinema-chains',
                href: '/cinema-chains',
                icon: <IconCinemaChain24 />,
                necessaryPermission: 'admin_administration'
            },
            {
                title: 'navigation.events',
                href: '/event-requests',
                icon: <IconProgram24 />,
                necessaryPermission: 'cinema_administration'
            }
        ]
    },
    {
        label: 'navigation.movies',
        children: [
            {
                title: 'navigation.movie-fetch-tmdb',
                icon: <IconSlate24 />,
                necessaryPermission: 'admin_administration',
                modal: 'fetchTmdbMovieModal'
            }
        ]
    },
    {
        label: 'navigation.distributors',
        children: [
            {
                title: 'navigation.distributors',
                href: '/distributors',
                icon: <IconDistributor24 />,
                necessaryPermission: 'admin_administration'
            }
        ]
    },
    {
        label: 'navigation.marketing-and-analytics',
        children: [
            {
                title: 'navigation.send-notifications',
                href: '/send-notifications',
                icon: <IconSend24 />,
                necessaryPermission: 'admin_administration'
            },
            // {
            //     title: 'navigation.talks',
            //     href: '/talks',
            //     icon: <IconComment24 />,
            //     necessaryPermission: 'admin_administration'
            // },
            {
                title: 'navigation.analytics',
                href: '/analytics',
                icon: <IconPieChart24 />,
                necessaryPermission: 'admin_administration'
            },
            {
                title: 'navigation.user-feedback',
                href: '/user-feedback',
                icon: <IconStar24 />,
                necessaryPermission: 'admin_administration'
            },
            {
                title: 'navigation.mad-report',
                href: '/mad-report',
                icon: <IconPieChart24 />,
                necessaryPermission: 'admin_administration'
            }
        ]
    },
    {
        label: 'navigation.orders-and-crm',
        children: [
            {
                title: 'navigation.orders',
                href: '/orders',
                icon: <IconBill24 />,
                necessaryPermission: 'admin_administration'
            }
        ]
    },
    {
        label: 'navigation.organization',
        children: [
            {
                title: 'navigation.user-administration',
                href: '/users',
                icon: <IconProfile24 />,
                necessaryPermission: 'admin_administration'
            },
            {
                title: 'navigation.role-administration',
                href: '/roles',
                icon: <IconRoleAdministration24 />,
                necessaryPermission: 'admin_administration'
            }
        ]
    }
];
