import React, { ReactChild } from 'react';

import useUserGetPermissions from './useUserGetPermissions';

export enum PermissionGateMode {
    default = 'default',
    hide = 'hide',
    error = 'error',
    detailedError = 'detailedError'
}

export type Props = {
    children: ReactChild;
    necessaryPermission?: string | null;
    mode: PermissionGateMode;
};

const hasPermission = async (permissions: string[], necessaryPermission: string) =>
    permissions.includes(necessaryPermission);

function PermissionsGate(props: Props): JSX.Element | null {
    const { children, necessaryPermission, mode } = props;
    const permissions = useUserGetPermissions();

    if (permissions) {
        const permissionGranted = necessaryPermission ? hasPermission(permissions, necessaryPermission) : true;

        if (!permissionGranted) {
            switch (mode) {
                case PermissionGateMode.default:
                    return <span className="bg-red bg-cyan">P E R M I S S I O N &#32;&#32; D E N I E D</span>;
                case PermissionGateMode.hide:
                    return null;
                case PermissionGateMode.error:
                    return <span className="bg-red bg-cyan">P E R M I S S I O N &#32;&#32; D E N I E D</span>;
                case PermissionGateMode.detailedError:
                    return (
                        <div className="">
                            You miss the permission:
                            <pre className="bg-red">{necessaryPermission}</pre>
                            Please contact you admin!
                        </div>
                    );
            }
        } else {
            return <>{children}</>;
        }
    } else {
        return <></>;
    }
}

export default PermissionsGate;
