import classnames from 'classnames';
import { useRouter } from 'next/router';
import { translateUrl } from 'next-translate-routes';
import React, { useEffect, useState } from 'react';

import { getMovieTitle } from '../../../helper/movie-helper';
import { IconForth12, IconForth16 } from '../../../icons/arrows/ic-forth';
import { BreadcrumbConfig } from '../../../middleware/breadcrumbMiddleware';
import { MovieDto } from '../../../models/movie/MovieDto.types';
import LinkWithStyle from '../link/LinkWithStyle';

type BreadcrumbItem = {
    breadcrumb: string;
    href: string;
};
// TODO: subbing in German letters cannot be done globally without un-desired spelling / gramatical mistakes. How can we do it sensitively?
// const convertBreadcrumb = (breadcrumbPathString: string, locale: string) => {
//     const stringWithSpaces = breadcrumbPathString.replace(/-/g, ' ')
//     if (locale === 'de'){
//         return stringWithSpaces
//             .replace(/oe/g, 'ö')
//             .replace(/ae/g, 'ä')
//             .replace(/ue/g, 'ü');
//     }
//     return stringWithSpaces
// };

const convertBreadcrumb = (breadcrumbPathString: string) => breadcrumbPathString?.replace(/-/g, ' ');

type BreadcrumbsProps<TProps extends Record<string, unknown>> = {
    indexPageTitle?: string;
    classNameText?: string;
    dividerArrowIconSize?: string;
    hideRootBreadcrumb?: boolean;
    pageProps: TProps;
};

const reconstructPathname = (pathname: string, params: string): string => {
    return pathname
        .split('/')
        .map((part) => {
            if (part.startsWith('[') && part.endsWith(']')) {
                const paramName = part.slice(1, -1);
                console.log('### paramName', paramName);
                return params[paramName];
            }
            return part;
        })
        .join('/');
};

function Breadcrumbs<TProps extends Record<string, unknown>>(props: BreadcrumbsProps<TProps>) {
    const { indexPageTitle, classNameText, dividerArrowIconSize, pageProps } = props;

    const router = useRouter();
    const [breadcrumbsItems, setBreadcrumbsItems] = useState<BreadcrumbItem[]>([]);
    // TODO: when api is wired, replace ids in router.query values with strings

    const movieProp: MovieDto = (pageProps.movieProp as MovieDto) || null;
    const locale: string = pageProps.locale as string;
    const breadcrumbConfig: BreadcrumbConfig = pageProps.breadcrumb as BreadcrumbConfig;

    useEffect(() => {
        if (router) {
            const linkPath = router.asPath.split('/');
            linkPath.shift();

            const pathArray = linkPath.map((path, i) => {
                const pathWithoutQuery =
                    linkPath.length === 1 && linkPath[0].length <= 0 ? indexPageTitle : path.split('?')[0];
                return {
                    breadcrumb: pathWithoutQuery,
                    href: `/${linkPath.slice(0, i + 1).join('/')}`
                };
            });

            //TODO: Add other cases to the switch to manipulate the 'breadcrumb' value. Distributors for instance.
            switch (pathArray[0].breadcrumb) {
                case 'movie-library':
                    pathArray[pathArray.length - 1].breadcrumb = getMovieTitle(movieProp, locale);
                    break;
                default:
                    break;
            }

            if (breadcrumbConfig?.parent) {
                Object.keys(breadcrumbConfig.parent)
                    .map((part) => +part)
                    .forEach((part) => {
                        pathArray[part].href = locale
                            ? translateUrl(breadcrumbConfig.parent[part], locale)
                            : breadcrumbConfig.parent[part];
                    });
            }

            if (!props.hideRootBreadcrumb) {
                setBreadcrumbsItems(pathArray);
            } else {
                setBreadcrumbsItems(pathArray.slice(1, pathArray.length));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    function DividerArrowIcon(props: { className: string }) {
        switch (dividerArrowIconSize) {
            case '12':
                return <IconForth12 className={props.className} />;
            default:
                return <IconForth16 className={props.className} />;
            // return <IconForth16 className="text-white text-opacity-50" />;
        }
    }

    if (!breadcrumbsItems) {
        return null;
    }

    function breadcrumbIsCurrentPage(breadcrumbItem: BreadcrumbItem): boolean {
        return breadcrumbItem.breadcrumb === breadcrumbsItems[breadcrumbsItems.length - 1].breadcrumb;
    }

    return (
        <nav aria-label="">
            <ul className="flex tracking-regular text-white space-x-8 items-center">
                {breadcrumbsItems.map((breadcrumbItem, i) => (
                    <li key={i}>
                        {!breadcrumbIsCurrentPage(breadcrumbItem) ? (
                            <div className="flex flex-row items-center space-x-8">
                                <LinkWithStyle
                                    className={classnames(
                                        'flex items-center space-x-8',
                                        'hover:text-cyan cursor-pointer capitalize',
                                        classNameText,
                                        {
                                            'opacity-50': i !== breadcrumbsItems.length - 1,
                                            'text-body-bold-16': !classNameText
                                        }
                                    )}
                                    href={breadcrumbItem.href}>
                                    {convertBreadcrumb(breadcrumbItem.breadcrumb)}
                                </LinkWithStyle>
                                {breadcrumbsItems?.length > 1 && i !== breadcrumbsItems.length - 1 && (
                                    <DividerArrowIcon className="text-white text-opacity-50" />
                                )}
                            </div>
                        ) : (
                            <div
                                className={classnames('capitalize', classNameText, {
                                    'opacity-50': i !== breadcrumbsItems.length - 1,
                                    'text-headline-20': !classNameText
                                })}>
                                {convertBreadcrumb(breadcrumbItem.breadcrumb)}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default Breadcrumbs;
