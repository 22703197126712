const useUserGetPermissions = () => [
    'admin_administration',
    'cinema_administration',
    'cinema_administration/edit',
    'cinema_administration/delete',
    'movie_administration',
    'movie_administration/edit',
    'movie_administration/delete'
];
export default useUserGetPermissions;
